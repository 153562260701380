import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import weather from 'vue-mini-weather'
import _ from 'lodash'
import axios from 'axios'
import dataV from '@jiaminghi/data-view'
// 引入store
import store from './store/index'
import VueParticles from 'vue-particles'
// 引入百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: '4WLu9nLsvvMXDGwS6Vt7osXcYQaEm9z0'  //这个地方是官方提供的ak**
})
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/theme.scss'
import 'vodal/common.css'
import echarts from 'echarts'// echarts
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/chart-bar.js'
import 'vue-awesome/icons/chart-area.js'
import 'vue-awesome/icons/chart-pie.js'
import 'vue-awesome/icons/chart-line.js'
import 'vue-awesome/icons/align-left.js'
// 适配flex
import '@/common/flexible.js'
// import 'default-passive-events'
import Vodal from 'vodal'

Vue.use(weather)
// 全局注册图标
Vue.component('icon', Icon)
Vue.component(Vodal.name, Vodal)
Vue.prototype.$echarts = echarts
//关闭VUE的生产提示
Vue.config.productionTip = false
Vue.prototype._ = _
Vue.use(ElementUI)
Vue.use(VueParticles)
Vue.use(dataV)
Vue.prototype.$axios = axios
Vue.directive('permission', {  
  bind(el, binding, vnode) {  
    const permissionName = binding.value;  
    if (!vnode.context.$store.state.permissions.includes(permissionName)) {  
      el.style.display = 'none'; // 隐藏元素  
    }  
  }  
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
