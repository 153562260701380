import axios from 'axios';
import Vue from 'vue'
import { Loading } from 'element-ui';
import router from '../router'

// 定义 loading 
let loading
// loading开始 方法
function startLoading() {
    // element-ui loading 服务调用方式  
    loading = Loading.service({
        lock: true,
        text: '拼命加载中...',
        spinner: 'el-icon-loading',  // 自定义图标
        background: 'rgba(0, 0, 0, 0.1)'
    })
}
// loading结束 方法
function endLoading() {
    loading.close()
}
const service = axios.create({
    // timeout: 10000,
    // retry: 3
});
const err = (error) => {
    if (error.toString().indexOf("Network Error") != -1) {
        Vue.prototype.$message({
            message: '后端服务未启动',
            type: 'error'
        })
        router.push('/');
    }
    endLoading();
    if (error.response) {
        const data = error.response.data
        const token = sessionStorage.getItem('token')

        if (error.response.status === 403) {
            console.log('服务器403啦，要重新登录！')
            Vue.prototype.$message({
                message: data.message,
                type: 'error'
            })
            router.push('/');
        }
        if (error.response.status === 500) {
            console.log('服务器内部错误！')
            // router.push('/W500');
            Vue.prototype.$message({
                message: '服务器内部错误！',
                type: 'error'
            })

        }
        if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
            Vue.prototype.$message({
                message: '自主校验失败',
                type: 'error'
            })
            if (token) {
                store.dispatch('Logout').then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                })
            }
        }
    }
    return Promise.reject(error)
}
// 添加请求拦截器
service.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token')
        if (token) {
            token ? config.headers.token = token : null;
        }
        // 请求显示loading 效果
        startLoading();

        return config
    }, err
)

// 添加响应拦截器
service.interceptors.response.use(
    (resp) => {
        endLoading();
        if (resp.request.responseType === 'blob') {
            return resp
        }
        let response = resp.data
        if (response.code === 10404 || response.code === 10403) {
            Vue.prototype.$message({
                message: '登录过期，请重新登录',
                type: 'error'
            })
            sessionStorage.removeItem('token')
            router.push('/');
        } else if (response.code === 10199 || response.code === 10200 || response.code === 10201 || response.code === 30092 || response.code === 30070 || response.code === 30132 || response.code === 50126 || response.code === 50095 || response.code === 50178 || response.code === 50183 || response.code === 11086 || response.code === 11089 || response.code === 12137 || response.code === 12120 || response.code === 12115 || response.code === 12107 || response.code === 12101 || response.code === 12046 || response.code === 12208) {
            alert(response.error)
            return response
        } else {
            return response
        }
    }, err)


export default service;