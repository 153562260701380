// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
// import auth from './modules/auth';
import user from './modules/user';
import createPersistedState from 'vuex-persistedstate';//数据持久化插件
 
Vue.use(Vuex);
 
export default new Vuex.Store({
  modules: {
    // auth,
    user,
    plugins: [createPersistedState({
      storage: window.sessionStorage, // 或者 localStorage
    })]
  }
});
  