import { getAllAuthList } from '../../api/index'


const state = {
  permissions: [], // 初始权限列表  
};
const mutations = {
  setPermissions(state, permissions) {
    state.permissions = permissions;
    // 同时保存到sessionStorage  
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
  },
  RESET_STATE(state) {  
    // 重置state  
    Object.keys(state).forEach(key => {  
      state[key] = typeof state[key] === 'object' && !Array.isArray(state[key]) ? {} : state[key] instanceof Array ? [] : state[key];  
    });  
  },  
};

const actions = {
  // 假设你有一个从后端获取权限的API  
  fetchPermissions({ commit }, roleList) {
    // 模拟API调用  
    try {
      // 假设你的API接收一个roleIds数组并返回权限数据         
      let authList = []
      let roleArr = Array.isArray(JSON.parse(roleList)) ? JSON.parse(roleList) :JSON.parse(JSON.parse(roleList))
      if(roleArr){
        roleArr.forEach(v => {
        // 这里应该是你的实际API调用代码 
        getAllAuthList(v).then(res => {
          res.data.forEach((m) => {
            authList.push(m);
          });
        })
      })
      setTimeout(() => {
        // 从API获取的权限数据   准确
        // 对authList去重
        // fn1(authList)
        const permissionsFromApi =  fn1(authList);
        commit('setPermissions', permissionsFromApi);
      }, 1000);
      }

      
    } catch (error) {
      // 处理错误，例如显示通知或记录日志  
      console.error('Error fetching permissions:', error);
      // 可以选择提交一个mutation来设置错误状态或显示通知  
    }

  },
  logout({ commit }) {  
    // 这里可以添加其他退出登录的逻辑，比如清除sessionStorage中的token等  
 
    // 调用mutation重置state  
    commit('RESET_STATE');  
  },  
};
const getters = {
  permissions: state => state.permissions
};
function fn1(tempArr) {
  for (let i = 0; i < tempArr.length; i++) {
      for (let j = i + 1; j < tempArr.length; j++) {
          if (tempArr[i].attribute_id == tempArr[j].attribute_id) {
              tempArr.splice(j, 1);
              j--;
          };
      };
  };
  return tempArr;
};
export default {
  state,
  mutations,
  actions,
  getters
};