import request from './request'
let base = 'http://47.104.237.15:9901/' //本地
// 这是登录
// 管理天气 http://www.weather.com.cn/data/cityinfo/101010100.html
export const getWeather = () => {
    return request({
        url: `http://47.104.237.15/ajax/test/weather.json`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
export const getTupuJson = () => {
    return request({
        url: `/ajax/test/1.json`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 权限登录管理
export const userLogin = query => {
    return request({
        url: `${base}API_V1.0/token/`,
        method: 'post',
        data: query,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  站点状态统计 API_V1.0/collection/site_transformer_state
 *@author: hanyan
 *@date: 2024-06-26 15:56:06
 *@version: V1.0.5
*/
export const getSitesState = () => {
    return request({
        url: `${base}API_V1.0/collection/site_transformer_state`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 传感器告警统计 API_V1.0/collection/warning_count
 *@author: hanyan
 *@date: 2024-06-26 15:03:59
 *@version: V1.0.5
*/
export const getWarningData = () => {
    return request({
        url: `${base}API_V1.0/collection/warning_count`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 首页最新预警数据 API_V1.0/collection/partial_discharge_data_warning_10
export const getYujingData = () => {
    return request({
        url: `${base}API_V1.0/collection/partial_discharge_data_warning_10`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

// 根据角色id查询用户权限列表 API_V1.0/user/attribute
export const getAllAuthList = (role_id) => {
    return request({
        url: `${base}API_V1.0/user/attribute/${role_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 查询所有权限列表 API_V1.0/user/attribute
export const getAllAuthListAll = () => {
    return request({
        url: `${base}API_V1.0/user/attribute`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 获取首页地图巡检坐标文件

export const xjLngPoint = query => {
    return request({
        // url: `${base}analysis/Lateral_analysis_A/` + query,
        url: `ttp://47.104.237.15/ajax/map/xjLng.json`,
        method: "get",
        headers: {
            token: sessionStorage.getItem("token")
        }
    });
};
// 获取所有坐标数据  API_V1.0/collection/map
export const xjLngMapPoint = query => {
    return request({
        // url: `${base}analysis/Lateral_analysis_A/` + query,
        url: `${base}API_V1.0/collection/map`,
        method: "get",
        headers: {
            token: sessionStorage.getItem("token")
        }
    });
};
// 铁岭市json数据
export const tieling = query => {
    return request({
        // url: `${base}analysis/Lateral_analysis_A/` + query,
        // url: `http://192.168.2.10:8080/map/tieling.json`,
        url: `/map/china.json`,
        method: "get"
    });
};
// 陕西省json数据
export const shanxi = query => {
    return request({
        // url: `${base}analysis/Lateral_analysis_A/` + query,
        // url: `http://192.168.2.10:8080/map/tieling.json`,
        url: `/map/广东省.json`,
        method: "get"
    });
};
// 西安市json数据
export const xian = query => {
    return request({
        url: `/map/深圳市.json`,
        method: "get"
    });
};
export const provinceMap = (province) => {
    return request({
        // url: `${base}analysis/Lateral_analysis_A/` + query,
        // url: `http://192.168.2.10:8080/map/tieling.json`,
        url: `/map/${province}.json`,
        method: "get"
    });
};


export const sensorTableList  = () => {
    return request({
        // url: `${base}tokens/`,
        url: '/ajax/tableList.json',
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

// 台账管理
/*
 *@description:  传感器查询 sensor/st
 *@author: hanyan
 *@date: 2024-05-22 09:39:50
 *@version: V1.0.5
*/
export const getAllsensor  = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 新增绑定 API_V1.0/collection/sensor_equipment
 *@author: hanyan
 *@date: 2024-06-26 12:44:14
 *@version: V1.0.5
*/
export const addDeviceSensor  = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor_equipment`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  新增站点绑定 API_V1.0/collection/site_test_suffer_equipment
 *@author: hanyan
 *@date: 2024-06-26 14:01:22
 *@version: V1.0.5
*/
export const addDeviceSite  = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/site_test_suffer_equipment`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询是否有过绑定  API_V1.0/collection/sensor_equipment_tse
 *@author: hanyan
 *@date: 2024-06-26 13:05:04
 *@version: V1.0.5
*/
export const getDeviceSensorOne  = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor_equipment_tse`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 传感器修改 API_V1.0/collection/sensor
 *@author: hanyan
 *@date: 2024-05-22 09:40:39
 *@version: V1.0.5
*/
export const editsensorById  = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 传感器增加 API_V1.0/collection/sensor
 *@author: hanyan
 *@date: 2024-05-22 09:41:05
 *@version: V1.0.5
*/
export const addsensor = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 传感器删除
 *@author: hanyan
 *@date: 2024-05-22 09:41:16
 *@version: V1.0.5
*/
export const delsensor = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 新建站点 collection/site  
 *@author: hanyan
 *@date: 2024-05-23 09:15:04
 *@version: V1.0.5
*/
export const addNewSite = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/site_transformer`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 修改站点
 *@author: hanyan
 *@date: 2024-05-23 09:17:31
 *@version: V1.0.5
*/
export const editNewSite = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/site_transformer`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询所有站点 API_V1.0/collection/site_transformer
 *@author: hanyan
 *@date: 2024-05-23 09:18:12
 *@version: V1.0.5
*/
export const getAllSites = () => {
    return request({
        url: `${base}API_V1.0/collection/site_transformer`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 删除站点
 *@author: hanyan
 *@date: 2024-05-23 09:18:22
 *@version: V1.0.5
*/
export const delSite = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/site_transformer`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 开关柜/变压器查询 API_V1.0/collection/test_suffer_equipment
 *@author: hanyan
 *@date: 2024-05-22 09:41:36
 *@version: V1.0.5
*/
export const getAllDevices = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 开关柜/变压器新建  API_V1.0/collection/test_suffer_equipment
 *@author: hanyan
 *@date: 2024-05-22 09:42:04
 *@version: V1.0.5
*/
export const addDevice = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 开关柜/变压器删除
 *@author: hanyan
 *@date: 2024-05-22 09:42:17
 *@version: V1.0.5
*/
export const delDevice = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 开关柜/变压器修改 API_V1.0/collection/test_suffer_equipment
 *@author: hanyan
 *@date: 2024-05-22 09:42:32
 *@version: V1.0.5
*/
export const editDevice = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
// 缺少绑定的操作
/*
 *@description:  台账导入
 *@author: hanyan
 *@date: 2024-05-22 09:44:10
 *@version: V1.0.5
*/

/*
 *@description: 台账导出
 *@author: hanyan
 *@date: 2024-05-22 09:44:27
 *@version: V1.0.5
*/

/*
 *@description: 接线图管理（查询）
 *@author: hanyan
 *@date: 2024-05-22 09:44:55
 *@version: V1.0.5
*/

/*
 *@description: 接线图新增
 *@author: hanyan
 *@date: 2024-05-22 09:45:06
 *@version: V1.0.5
*/

/*
 *@description: 传感器类型数据统计 /API_V1.0/collection/sensor_type_count
 *@author: hanyan
 *@date: 2024-06-25 14:22:36
 *@version: V1.0.5
*/
export const getSensorCount = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/sensor_type_count`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
export const getDeviceCount = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/equipment_type_count`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};


// 获取设备类型
export const equipmentType = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/equipment_type`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 用户管理（增删查改） API_V1.0/user/userfrom
 *@author: hanyan
 *@date: 2024-05-22 09:47:19
 *@version: V1.0.5
*/
export const userAdd = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/user/userfrom`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
export const userDel = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/user/userfrom`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
export const userEdit = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/user/userfrom`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
export const userSearchAll = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/user/userfrom`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询树形结构 API_V1.0/collection/site_transformer_test_suffer_equipment
 *@author: hanyan
 *@date: 2024-05-24 11:39:53
 *@version: V1.0.5
*/
export const getTreeConstructure = () => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/site_transformer_test_suffer_equipment`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 查询台账列表（以网关>设备查出树形结构数据）
 *@author: hanyan
 *@date: 2024-05-22 10:29:59
 *@version: V1.0.5
*/
/*
 *@description: 实时数据（根据变压器信息查询某变压器上实时数据、运行状态、有无故障信息） collection/nowadays/<int:test_equipment_id>
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
export const getPresentDataById = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment_real`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 历史数据（根据变压器信息查询某变压器上历史数据多少种传感器就穿多少种数据） API_V1.0/collection/test_suffer_equipment_history
 *@author: hanyan    参数[sensor_type_id, test_suffer_equipment_id, sensor_id]
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
export const getHistoryDataById = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/test_suffer_equipment_history`,
        // url: `http://192.168.2.10:8080/ajax/test/oneDevicehisData.json`,
        method: 'post',
        // method: 'get',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 横向分析 collection/horizontal_analysis/
 *@author: hanyan
 *@date: 2024-06-21 11:27:42
 *@version: V1.0.5
*/
export const getHengXDataById = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/horizontal_analysis/${data}`,
        method: 'get',
        // method: 'get',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 纵向分析  collection/vertical_analysis
 *@author: hanyan
 *@date: 2024-06-21 11:28:06
 *@version: V1.0.5
*/
export const getZongXDataById = (data) => {
    return request({
        // url: `${base}tokens/`,
        url: `${base}API_V1.0/collection/vertical_analysis`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询台账列表（以网关>设备查出树形结构数据）
 *@author: hanyan
 *@date: 2024-05-22 10:29:59
 *@version: V1.0.5
*/
/*
 *@description: 数据管理，根据站点/网关id查询当前站的横向分析、纵向分析数据
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
/*
 *@description: 后台数据管理 （删除，模糊查询）
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
/*
 *@description: 权限管理 （增删查改）查询权限  API_V1.0/collection/path
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
export const getAllAuths = () => {
    return request({
        url: `${base}API_V1.0/user/attribute`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 新建权限 
 *@author: hanyan
 *@date: 2024-05-24 17:37:26
 *@version: V1.0.5
*/
export const addNewAuth = (data) => {
    return request({
        url: `${base}API_V1.0/user/attribute`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 修改权限
 *@author: hanyan
 *@date: 2024-05-24 17:37:37
 *@version: V1.0.5
*/
export const editAuth = (data) => {
    return request({
        url: `${base}API_V1.0/user/attribute`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 删除权限
 *@author: hanyan
 *@date: 2024-05-24 17:37:49
 *@version: V1.0.5
*/
export const delAuth = (data) => {
    return request({
        url: `${base}API_V1.0/user/attribute`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 新建授权 API_V1.0/collection/path_authority
 *@author: hanyan
 *@date: 2024-05-27 10:28:55
 *@version: V1.0.5
*/
export const addshouAuth = (data) => {
    return request({
        url: `${base}API_V1.0/collection/path_authority`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 编辑授权 collection/path_authority
 *@author: hanyan
 *@date: 2024-05-27 10:29:50
 *@version: V1.0.5
*/
export const editshouAuth = (data) => {
    return request({
        url: `${base}API_V1.0/collection/path_authority`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 删除授权
 *@author: hanyan
 *@date: 2024-05-27 10:30:04
 *@version: V1.0.5
*/
export const delshouAuth = (data) => {
    return request({
        url: `${base}API_V1.0/collection/path_authority`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询授权
 *@author: hanyan
 *@date: 2024-05-27 10:30:16
 *@version: V1.0.5
*/
export const getAllshouAuth = (data) => {
    return request({
        url: `${base}API_V1.0/collection/path_authority`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 角色管理 （增删查改） 查询角色 API_V1.0/user/role
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/
export const getAllRoles = () => {
    return request({
        url: `${base}API_V1.0/user/role`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 角色增加  API_V1.0/user/role [role]
 *@author: hanyan
 *@date: 2024-05-24 15:49:48
 *@version: V1.0.5
*/
export const addNewRole = (data) => {
    return request({
        url: `${base}API_V1.0/user/role`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 角色修改  API_V1.0/user/role [role_id]
 *@author: hanyan
 *@date: 2024-05-24 15:49:48
 *@version: V1.0.5
*/
export const editRole = (data) => {
    return request({
        url: `${base}API_V1.0/user/role`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 角色删除  API_V1.0/collection/role [role_id]
 *@author: hanyan
 *@date: 2024-05-24 15:49:48
 *@version: V1.0.5
*/
export const delRole = (data) => {
    return request({
        url: `${base}API_V1.0/user/role`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 用户管理 （增删查改）
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/

/*
 *@description: 传感器绑定（某设备）管理（增删查改）
 *@author: hanyan
 *@date: 2024-05-22 10:28:44
 *@version: V1.0.5
*/

/*
 *@description: 新建用户与角色关系 API_V1.0/collection/role_user
 *@author: hanyan
 *@date: 2024-05-27 11:06:19
 *@version: V1.0.5
*/
export const addNewRelation = (data) => {
    return request({
        url: `${base}API_V1.0/collection/role_user`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: API_V1.0/collection/role_user 删除用户与角色关系
 *@author: hanyan
 *@date: 2024-05-27 11:07:05
 *@version: V1.0.5
*/
export const delRelation = (data) => {
    return request({
        url: `${base}API_V1.0/collection/role_user`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 修改用户与角色关系 API_V1.0/collection/role_user
 *@author: hanyan
 *@date: 2024-05-27 11:07:35
 *@version: V1.0.5
*/
export const editRelation = (data) => {
    return request({
        url: `${base}API_V1.0/collection/role_user`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 查询用户与角色关系 API_V1.0/collection/role_user
 *@author: hanyan
 *@date: 2024-05-27 11:07:54
 *@version: V1.0.5
*/
export const showRelation = (user_id) => {
    return request({
        url: `${base}API_V1.0/user/user_role/${user_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 新建用户与角色关系 API_V1.0/user/role_user
 *@author: hanyan
 *@date: 2024-05-27 11:07:54
 *@version: V1.0.5
*/
export const addUserRole = (data) => {
    return request({
        url: `${base}API_V1.0/user/role_user`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 修改用户与角色关系 API_V1.0/user/role_user
 *@author: hanyan
 *@date: 2024-05-27 11:07:54
 *@version: V1.0.5
*/
export const editUserRole = (data) => {
    return request({
        url: `${base}API_V1.0/user/role_user`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 新建角色授权权限  API_V1.0/user/role_attribute
 *@author: hanyan
 *@date: 2024-06-13 15:13:46
 *@version: V1.0.5
*/
export const addRoleAuths = (data) => {
    return request({
        url: `${base}API_V1.0/user/role_attribute`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  修改角色授权 API_V1.0/user/role_attribute
 *@author: hanyan
 *@date: 2024-06-13 15:14:35
 *@version: V1.0.5
*/
export const editRoleAuths = (data) => {
    return request({
        url: `${base}API_V1.0/user/role_attribute`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  查询字典数据 API_V1.0/collection/tripartite_parameters_type
 *@author: hanyan
 *@date: 2024-06-27 14:13:40
 *@version: V1.0.5
*/
export const getdicData = () => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters_type`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 通过id查找什么解释 API_V1.0/collection/tripartite_parameters/3
 *@author: hanyan
 *@date: 2024-06-27 15:27:03
 *@version: V1.0.5
*/
export const getdicDataByExplain = (explain) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters/${explain}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 添加k和v  API_V1.0/collection/tripartite_parameters_type
 *@author: hanyan
 *@date: 2024-06-27 15:31:53
 *@version: V1.0.5
*/
export const addDicK = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters_type`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  修改k值
 *@author: hanyan
 *@date: 2024-06-27 16:56:51
 *@version: V1.0.5
*/
export const editDicK = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters_type`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 删除k值
 *@author: hanyan
 *@date: 2024-06-27 17:02:07
 *@version: V1.0.5
*/
export const deleteDicK = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters_type`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 添加v  API_V1.0/collection/tripartite_parameters_type
 *@author: hanyan
 *@date: 2024-06-27 15:31:53
 *@version: V1.0.5
*/
export const addDicV = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description:  修改v
 *@author: hanyan
 *@date: 2024-06-27 17:15:10
 *@version: V1.0.5
*/
export const editDicV = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters`,
        method: 'put',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description:  通过删除v
 *@author: hanyan
 *@date: 2024-06-27 17:15:10
 *@version: V1.0.5
*/
export const deleteDicV = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description:  查询v
 *@author: hanyan
 *@date: 2024-06-27 17:15:10
 *@version: V1.0.5
*/
export const searchAllDicV = () => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parametersall`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 修改绑定 API_V1.0/collection/parameters_type_tripartite_parameters
 *@author: hanyan
 *@date: 2024-06-28 10:19:42
 *@version: V1.0.5
*/
export const addkDicV = (data) => {
    return request({
        url: `${base}API_V1.0/collection/parameters_type_tripartite_parameters`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 传感器绑定其他参数 API_V1.0/collection/sensor_position
 *@author: hanyan
 *@date: 2024-06-28 14:19:23
 *@version: V1.0.5
*/
export const addSensorInfo = (data) => {
    return request({
        url: `${base}API_V1.0/collection/sensor_position`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 纵向分析 API_V1.0/collection/vertical_analysis
 *@author: hanyan
 *@date: 2024-06-28 16:11:53
 *@version: V1.0.5
*/
export const getZXNew = (data) => {
    return request({
        url: `${base}API_V1.0/collection/vertical_analysis`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 加载某个站点下的设备 API_V1.0/collection/site_transformer_in_test_suffer_equipment/
 *@author: hanyan
 *@date: 2024-07-01 15:25:35
 *@version: V1.0.5
*/
export const getDevicesById= (site_id) => {
    return request({
        url: `${base}API_V1.0/collection/site_transformer_in_test_suffer_equipment/${site_id}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 得到画图组态数据
 *@author: hanyan
 *@date: 2024-07-08 14:47:14
 *@version: V1.0.5
*/
export const getTopoDataById = (site_id) => {
    return request({
        // url: `${base}API_V1.0/collection/site_transformer_in_test_suffer_equipment/${site_id}`,
        url: `/ajax/test/topo_json.json`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description: 新增接线图 API_V1.0/collection/chart
 *@author: hanyan
 *@date: 2024-07-09 09:18:46
 *@version: V1.0.5
*/
export const addjxtInfo = (data) => {
    return request({
        url: `${base}API_V1.0/collection/chart`,
        method: 'post',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description: 查询所有一次接线图 API_V1.0/collection/chart
 *@author: hanyan
 *@date: 2024-07-09 09:26:00
 *@version: V1.0.5
*/
export const getAlljxtInfo = () => {
    return request({
        // url: `${base}API_V1.0/collection/chart`,
        url: `${base}/API_V1.0/collection/chart`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/* 
 *@description: 删除某站点接线图  delete  API_V1.0/collection/chart
 *@author: hanyan
 *@date: 2024-07-09 09:59:45
 *@version: V1.0.5
*/
export const deleteAlljxtInfo = (data) => {
    return request({
        // url: `${base}API_V1.0/collection/chart`,
        url: `${base}/API_V1.0/collection/chart`,
        method: 'delete',
        data: data,
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/* 
 *@description: 搜索站点管理信息 get  API_V1.0/collection/site_transformer_vague/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const searchByqInfo = (data) => {
    return request({
        url: `${base}API_V1.0/collection/site_transformer_vague/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/* 
 *@description: 设备管理模糊查询 get  API_V1.0/collection/test_suffer_equipment_vague/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const searchequipmentInfo = (data) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_vague/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/* 
 *@description: 字典类型模糊查询 get  /API_V1.0/collection/tripartite_parameters_type_vague/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const searchparametersType = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parameters_type_vague/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/* 
 *@description: 字典数据模糊查询 get  /API_V1.0/collection/tripartite_parametersall_vague/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const searchparametersallInfo = (data) => {
    return request({
        url: `${base}API_V1.0/collection/tripartite_parametersall_vague/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/* 
 *@description: 站点故障率统计 get  /API_V1.0/collection/test_suffer_equipment_fault_count/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const equipmentFaultCount = (data) => {
    return request({
        url: `${base}API_V1.0/collection/test_suffer_equipment_fault_count/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/* 
 *@description: 设备统计 get  /API_V1.0/collection/test_suffer_equipment_fault_count/${param}
 *@author: xing
 *@date: 2024-07-16 09:59:45
 *@version: V1.0.5
*/
export const equipmentCount = (data) => {
    return request({
        url: `${base}API_V1.0/collection/sensor_equipment_count/${data}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  prpd图谱
 *@author: hanyan
 *@date: 2024-08-08 09:17:40
 *@version: V1.0.5
*/
export const singlePrpds = (data) => {
    return request({
        url: `/ajax/test/prpd.json`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};