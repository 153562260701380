import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
// import Index from '../components/Index.vue'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
    {
        name: 'home',
        path: '/Whome',
        component: () =>
            import('../view/home/index.vue'),
        children: [
            {
                path: '/sensorByqInfo',
                name: 'sensorByqInfo',
                component: () =>
                    import('../view/my-sensorByqInfo/index.vue'),
                meta: { title: '台账管理', requiresPermission: true },
                redirect: '/sensorByqInfo/byq',
                children: [
                    {
                        path: 'sensor',
                        name: 'Sensor',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/sensorInfo.vue'),
                        meta: {
                            title: '传感器数据', requiresPermission: true
                        }
                    },
                    {
                        path: 'kgg',
                        name: 'Kgg',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/kggInfo.vue'),
                        meta: {
                            title: '开关柜数据', requiresPermission: true
                        }
                    },
                    {
                        path: 'byq',
                        name: 'Byq',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/byqInfo.vue'),
                        meta: {
                            title: '变压器数据', requiresPermission: true
                        }
                    },
                    {
                        path: 'dictionaryType',
                        name: 'Dic',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/typeInfo.vue'),
                        meta: {
                            title: '字典类型', requiresPermission: true
                        }
                    },
                    {
                        path: 'dictionaryData',
                        name: 'DicData',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/typeDetails.vue'),
                        meta: {
                            title: '字典数据', requiresPermission: true
                        }
                    },
                    // jxtData
                    {
                        path: 'jxtuData',
                        name: 'jxtData',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/jxtInfo.vue'),
                        meta: {
                            title: '字典数据', requiresPermission: true
                        }
                    },
                    {
                        path: 'caseRecommend',
                        name: 'caseRecommend',
                        component: () =>
                            import('../view/my-sensorByqInfo/component/caseRecommend.vue'),
                        meta: {
                            title: '案例推荐', requiresPermission: true
                        }
                    },
                    
                ]
            },
            // {
            //     path: '/fault',
            //     name: 'fault',
            //     component: () =>
            //         import('../view/my-decision/1.vue'),
            //     meta: { title: '故障诊断' },
            //     redirect: '/fault/first',
            //     children: [
            //         {
            //             path: 'first',
            //             name: 'first',
            //             component: () =>
            //                 import('../view/my-decision/components/first.vue'),
            //             meta: {
            //                 title: '无设备页面', requiresPermission: true
            //             }
            //         },
            //         {
            //             path: 'second',
            //             name: 'second',
            //             component: () => import('../view/my-decision/components/second.vue'),
            //             meta: { title: '数据分析' },
            //         },
            //         {
            //             path: 'third',
            //             name: 'third',
            //             component: () => import('../view/my-decision/components/third.vue'),
            //             meta: { title: '智能决策' },
            //         },
            //         {
            //             path: 'fourth',
            //             name: 'fourth',
            //             component: () => import('../view/my-decision/components/fourth.vue'),
            //             meta: { title: '智能决策-子页面' },
            //         },
            //     ]
            // },
            {
                path: '/dataAnalysis',
                name: 'dataAnalysis',
                component: () =>
                    import('../view/my-dataAnalysis/index.vue'),
                meta: { title: '风险预警' },
                redirect: '/dataAnalysis/index',
                children: [
                    {
                        path: 'index',
                        name: 'index',
                        component: () =>
                            import('../view/my-dataAnalysis/component/nodevice.vue'),
                        meta: {
                            title: '无设备页面', requiresPermission: true
                        }
                    },
                    {
                        path: 'analysis',
                        name: 'analysis',
                        component: () => import('../view/my-dataAnalysis/component/analysis.vue'),
                        meta: { title: '数据分析' },
                    },
                ]
            },
            {
                path: '/statusMng',
                name: 'statusMng',
                component: () => import('../view/my-statusMng/index.vue'),
                meta: { title: '状态管理' },
                redirect: '/statusMng/index',
                children: [
                    {
                        path: 'index',
                        name: 'index',
                        component: () =>
                            import('../view/my-statusMng/components/nodevice.vue'),
                        meta: {
                            title: '局放诊断', requiresPermission: true
                        }
                    },
                    {
                        path: 'statusMng',
                        // name: 'statusMng',
                        component: () =>
                            import('../view/my-statusMng/components/statusMng.vue'),
                        meta: {
                            title: '设备层面', requiresPermission: true
                        }
                    },
                ]
            },
            {
                path: '/faultDiag',
                name: 'faultDiag',
                component: () => import('../view/my-faultDiag/index.vue'),
                meta: { title: '故障诊断' },
                redirect: '/faultDiag/first',
                children: [
                    {
                        path: "first",
                        name: "first",
                        component: ()=> import('../view/my-decision/components/first.vue'),
                        meta: {
                            title: '故障诊断', requiresPermission: true
                        }
                    },
                    {
                        path: "second",
                        name: "second",
                        component: ()=> import('../view/my-decision/components/second.vue'),
                        meta: {
                            title: '故障诊断子界面', requiresPermission: true
                        }
                    }
                ]
            },
            {
                path: '/faultDiag-2',
                name: 'faultDiag',
                component: () => import('../view/my-faultDiag/index-2.vue'),
                meta: { title: '故障诊断' }
            },
            {
                path: '/equipment',
                name: 'equipment',
                component: () =>
                    import('../view/my-equipment/index.vue'),
                meta: {
                    title: '设备层面', requiresPermission: true
                }
            },
            {
                path: '/tupuDiagnoise-2',
                name: 'faultDiag',
                component: () => import('../view/my-decision/index-2.vue'),
                meta: { title: '预警' }
            },
            {
                path: '/tupuDiagnoise',
                name: 'tupuDiagnoise',
                redirect: '/tupuDiagnoise/third',
                component: () => import('../view/my-decision/index.vue'),
                meta: { title: '首页', requiresPermission: true },
                children: [
                    {
                        path: "third",
                        name: "third",
                        component: ()=> import('../view/my-decision/components/third.vue'),
                        meta: {
                            title: '智能决策', requiresPermission: true
                        }
                    },
                    {
                        path: "fourth",
                        name: "fourth",
                        component: ()=> import('../view/my-decision/components/fourth.vue'),
                        meta: {
                            title: '智能决策子界面', requiresPermission: true
                        }
                    }
                ]
            },
            {
                path: '/data',
                name: 'data',
                component: () =>
                    import('../view/home-data/index.vue'),
                meta: { title: '首页', requiresPermission: true },
                redirect: '/data/home',
                children:[
                    {
                        path: 'home',
                        name: 'home',
                        component: () =>
                            import('../view/home-data/indexA.vue'),
                        meta: { title: '站点详情' }
                    },
                    {
                    path: 'station',
                    name: 'station',
                    component: () =>
                        import('../view/home-data/stationIndex.vue'),
                    meta: { title: '站点详情' }
                }
            ]
            },
            {
                path: '/backend',
                name: 'backend',
                component: () =>
                    import('../view/my-backendManage/index.vue'),
                meta: {
                    title: '后台管理', requiresPermission: true
                },
                redirect: '/backend/BackAuth',
                children: [
                    {
                        path: 'backAuth',
                        name: 'BackAuth',
                        component: () =>
                            import('../view/my-backendManage/component/authInfo.vue'),
                        meta: {
                            title: '权限管理', requiresPermission: true
                        }
                    },
                    {
                        path: 'backRole',
                        name: 'BackRole',
                        component: () =>
                            import('../view/my-backendManage/component/roleInfo.vue'),
                        meta: {
                            title: '角色管理', requiresPermission: true 
                        }
                    },
                    {
                        path: 'backUser',
                        name: 'BackUser',
                        component: () =>
                            import('../view/my-backendManage/component/userInfo.vue'),
                        meta: {
                            title: '用户管理', requiresPermission: true 
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/',
        name: 'login',
        component: () =>
            import('../view/login/login.vue'),
        meta: { title: '登录' }
    },
    { // 404路由
        path: '/404',
        name: '404',
        component: () =>
            import('../view/404.vue'),
        meta: {
            requiresAuth: false // 这个路由不需要用户认证  
        }
    },
    { // 500路由
        path: '/500',
        name: '500',
        component: () =>
            import('../view/500.vue'),
        meta: {
            requiresAuth: false // 这个路由不需要用户认证  
        }
    },
    {
        path: '/no-access', // 页面不存在的情况下会跳到404页面
        name: 'noAccess',
        component: () =>
            import('../view/404.vue'),
        meta: {
            requiresAuth: false // 这个路由不需要用户认证  
        }
    },
    // {
    //     path: '*', // 页面不存在的情况下会跳到404页面
    //     redirect: '/404',
    //     name: '404',
    //     meta: {
    //         requiresAuth: false // 这个路由不需要用户认证  
    //     }
    // }
]
const router = new Router({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    let permissions = store.getters.permissions
    console.log(to,from,'to,from');
    
    // 判断是否未登录状态
    if (to.matched.length === 0) { //匹配前往的路由不存在
        console.log(to.matched,'to.matched');
        
        from.name ? next({
            name: from.name
        }) : next('/404'); //判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
    } else {
        if (sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== '') {
            next(); // 允许访问  
        } else {
            // 去登陆页面
            if (to.name === 'login') {
                next();
                return
            } else {
                Vue.prototype.$message({
                    message: '登录信息失效，请重新登录',
                    type: 'error'
                })
                next('/')
            }
        }
    }
    // 解决刷新路由vuex中user权限对象消失的问题
    if (permissions.length==0 || permissions == 'undefined') {
        let role_ids = sessionStorage.getItem("roleIds")
        store.dispatch('fetchPermissions', JSON.stringify(role_ids));
    }
})
export default router